import { Controller } from '@hotwired/stimulus'

/**
 * 学生の声、開閉用の Controller
 */
export class VoiceController extends Controller {
  /**
   * connect
   */
  connect () {
    const voiceContainer = this.containerTarget.querySelectorAll('.voice__content')
    const voiceButtons = this.containerTarget.querySelectorAll('.voice__content-button')
    this.contentClickRedy(voiceContainer, voiceButtons)
  }

  /**
   * ボタンがクリックされた時のイベントを準備
   *
   * @param {HTMLElement} voiceContainer
   * @param {HTMLElement} voiceButtons
   */
  contentClickRedy (voiceContainer, voiceButtons) {
    voiceButtons.forEach((voiceButton, index) => {
      voiceButton.addEventListener('click', () => {
        this.toggle(voiceContainer[index])
      })
    })
  }

  /**
   * ボタンが押された時のクラスの付け替えを行う
   *
   * @param {HTMLElement} voiceContent
   */
  toggle (voiceContent) {
    voiceContent.classList.toggle('voice__content--active')
  }
}

VoiceController.targets = ['content', 'container']
